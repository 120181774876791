import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`"Repful"`}</h1>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` that differentiates one `}<a parentName="li" {...{
          "href": "/group",
          "title": "group"
        }}>{`group`}</a>{` from another `}<a parentName="li" {...{
          "href": "/group",
          "title": "group"
        }}>{`group`}</a>{` is said to be representative of that group, or repful for short`}</li>
      <li parentName="ul">{`Repful comments are amenable to building data sentences (thanks to `}<a parentName="li" {...{
          "href": "https://twitter.com/enjalot"
        }}>{`Ian Johnson`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      